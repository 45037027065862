import { faFacebook, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

export interface SideBarProps {
    facebook?: string;
    twitter?: string;
    linkedin?: string;
    github?: string;
    email?: string;
}

const SidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    svg {
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    a {
        color: white;
    }

    a:hover {
        color: #0652dd !important;
    }
`;

const SidebarItem = styled.div`
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
`;

const SocialWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const EmailWrapper = styled.div`
    transform: rotate(-90deg);
    letter-spacing: 0.2rem;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 15px;

    p {
        margin-left: 1.2rem;
    }
`;

export const Sidebar = (props: SideBarProps) => {
    return (
        <SidebarWrapper>
            <SidebarItem>
                <SocialWrapper>
                    {props.github ? (
                        <a href={props.github} target="_blank" rel="noreferrer" referrerPolicy="no-referrer">
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                    ) : (
                        ''
                    )}
                    {props.linkedin ? (
                        <a href={props.linkedin} target="_blank" rel="noreferrer" referrerPolicy="no-referrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    ) : (
                        ''
                    )}
                    {props.twitter ? (
                        <a href={props.twitter} target="_blank" rel="noreferrer" referrerPolicy="no-referrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    ) : (
                        ''
                    )}
                    {props.facebook ? (
                        <a href={props.facebook} target="_blank" rel="noreferrer" referrerPolicy="no-referrer">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                    ) : (
                        ''
                    )}
                </SocialWrapper>
            </SidebarItem>
            <SidebarItem>
                <EmailWrapper>
                    {props.email ? (
                        <a
                            href={`mailto: ${props.email}`}
                            target="_blank"
                            rel="noreferrer"
                            referrerPolicy="no-referrer"
                            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                        >
                            <FontAwesomeIcon icon={faEnvelope} />{' '}
                            <p>{props.email.replace('@', '\u00A0@\u00A0').replace('.', '\u00A0.\u00A0')}</p>
                        </a>
                    ) : (
                        ''
                    )}
                </EmailWrapper>
            </SidebarItem>
        </SidebarWrapper>
    );
};

export default Sidebar;
