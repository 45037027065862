import React from 'react';
import styled from 'styled-components';

export interface ButtonProps {
    children?: any;
    customStyling?: any;
    padding?: 'large' | 'medium' | 'small';
    type?: 'button' | 'submit';
}

const ButtonWrapper = styled.button<{ padding?: string }>`
    border: 0;
    border-radius: 5px;
    color: white !important;
    background-color: #0652dd;
    padding: ${props => (props.padding === 'large' ? '10px' : props.padding === 'medium' ? '5px 10px' : '5px')};
    font-family: 'wotfardregular';
    font-size: 16px;
    transition: 0.3s;

    &:hover {
        border: 0;
        border-radius: 5px;
        outline: 0;
        cursor: pointer;
        background-color: #053285 !important;
        color: white;
        -webkit-text-fill-color: white;
    }

    &:active {
        border: 0;
    }
`;

export const Button = ({ children, customStyling, padding = 'medium', type = 'button' }: ButtonProps) => {
    return (
        <ButtonWrapper padding={padding} style={customStyling} type={type}>
            {children}
        </ButtonWrapper>
    );
};

export default Button;
