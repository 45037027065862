import { faFacebook, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBars, faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Link as LinkWithScroll } from 'react-scroll';
import styled from 'styled-components';
import config from '../config';
import DarkModeToggle from './DarkModeToggle';

const NavWrapper = styled(motion.nav)`
    position: fixed;
    height: 100vh;
    width: 70vw;
    background-color: #0752dd;
    color: white;
    z-index: 100;
    top: 0;
    right: 0;
`;

const Nav = styled.div`
    padding: 3rem;
    font-size: 1.2rem;

    ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    li {
        margin-bottom: 1rem;
    }

    li:hover {
        cursor: pointer;
    }

    a {
        color: white;
        text-decoration: none;

        &:visited {
            text-decoration: none;
            color: var(--textColor);
        }
    }
`;

const Blur = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 50;
    backdrop-filter: blur(2px);
`;

const MenuIconWrapper = styled.div`
    color: var(--textNormal);
`;

const OpenMenuIconWrapper = styled.div`
    position: fixed;
    color: white;
    top: 1.5rem;
    right: 1.5rem;
`;

const SocialList = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    flex-wrap: wrap;
`;

export const MobileNav = () => {
    const [menuActive, toggleMenu] = useState(false);
    const variants = {
        open: {
            opacity: 1,
            x: 0,
            transition: {
                type: 'tween',
            },
        },
        closed: {
            opacity: 0,
            x: '100%',
            transition: {
                type: 'spring',
            },
        },
    };

    const navVariants = {
        open: {
            transition: { staggerChildren: 0.07, delayChildren: 0.2 },
        },
        closed: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 },
        },
    };

    const navItemVariants = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -100 },
            },
        },
        closed: {
            y: 50,
            opacity: 0,
            transition: {
                y: { stiffness: 1000 },
            },
        },
    };

    return (
        <>
            <MenuIconWrapper>
                <FontAwesomeIcon
                    icon={faBars}
                    onClick={() => {
                        toggleMenu(true);
                    }}
                />
            </MenuIconWrapper>
            {menuActive && <Blur onClick={() => toggleMenu(false)} />}
            <NavWrapper animate={menuActive ? 'open' : 'closed'} variants={variants}>
                <OpenMenuIconWrapper>
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                            toggleMenu(false);
                        }}
                    />
                </OpenMenuIconWrapper>
                <Nav>
                    <motion.ol variants={navVariants}>
                        <motion.li variants={navItemVariants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                            <DarkModeToggle />
                        </motion.li>
                        {config.nav?.items?.map(item => (
                            <motion.li
                                variants={navItemVariants}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                key={`menu-item-${item.navigateTo}`}
                            >
                                <LinkWithScroll
                                    to={item.navigateTo}
                                    smooth={item.smooth}
                                    offset={item.offset}
                                    duration={item.duration}
                                >
                                    {item.text}
                                </LinkWithScroll>
                            </motion.li>
                        ))}
                        <motion.li variants={navItemVariants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                            <SocialList>
                                {config?.sidebar?.email && (
                                    <a
                                        href={`mailto: ${config?.sidebar?.email}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        referrerPolicy="no-referrer"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEnvelope} /> &nbsp;{' '}
                                    </a>
                                )}
                                {config?.sidebar?.github && (
                                    <a
                                        href={config?.sidebar?.github}
                                        target="_blank"
                                        rel="noreferrer"
                                        referrerPolicy="no-referrer"
                                    >
                                        <FontAwesomeIcon icon={faGithub} />
                                    </a>
                                )}
                                {config?.sidebar?.linkedin && (
                                    <a
                                        href={config?.sidebar?.linkedin}
                                        target="_blank"
                                        rel="noreferrer"
                                        referrerPolicy="no-referrer"
                                    >
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </a>
                                )}
                                {config?.sidebar?.twitter && (
                                    <a
                                        href={config?.sidebar?.twitter}
                                        target="_blank"
                                        rel="noreferrer"
                                        referrerPolicy="no-referrer"
                                    >
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                )}
                                {config?.sidebar?.facebook && (
                                    <a
                                        href={config?.sidebar?.facebook}
                                        target="_blank"
                                        rel="noreferrer"
                                        referrerPolicy="no-referrer"
                                    >
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </a>
                                )}
                            </SocialList>
                        </motion.li>
                    </motion.ol>
                </Nav>
            </NavWrapper>
        </>
    );
};

export const BlogMobileNav = () => {
    const [menuActive, toggleMenu] = useState(false);

    return (
        <>
            <MenuIconWrapper>
                <FontAwesomeIcon
                    icon={faBars}
                    onClick={() => {
                        toggleMenu(true);
                    }}
                />
            </MenuIconWrapper>
            {menuActive && (
                <>
                    <Blur onClick={() => toggleMenu(false)} />
                    <NavWrapper animate={menuActive ? 'open' : 'closed'}>
                        <OpenMenuIconWrapper>
                            <FontAwesomeIcon
                                icon={faTimes}
                                onClick={() => {
                                    toggleMenu(false);
                                }}
                            />
                        </OpenMenuIconWrapper>
                        <Nav>
                            <ol>
                                <li>
                                    <DarkModeToggle />
                                </li>
                                <li>
                                    <Link to="/">About</Link>
                                </li>
                                <li>
                                    <Link to="/blog">Blog</Link>
                                </li>
                                <li>
                                    <SocialList>
                                        {config?.sidebar?.email && (
                                            <a
                                                href={`mailto: ${config?.sidebar?.email}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                referrerPolicy="no-referrer"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faEnvelope} /> &nbsp;{' '}
                                            </a>
                                        )}
                                        {config?.sidebar?.github && (
                                            <a
                                                href={config?.sidebar?.github}
                                                target="_blank"
                                                rel="noreferrer"
                                                referrerPolicy="no-referrer"
                                            >
                                                <FontAwesomeIcon icon={faGithub} />
                                            </a>
                                        )}
                                        {config?.sidebar?.linkedin && (
                                            <a
                                                href={config?.sidebar?.linkedin}
                                                target="_blank"
                                                rel="noreferrer"
                                                referrerPolicy="no-referrer"
                                            >
                                                <FontAwesomeIcon icon={faLinkedin} />
                                            </a>
                                        )}
                                        {config?.sidebar?.twitter && (
                                            <a
                                                href={config?.sidebar?.twitter}
                                                target="_blank"
                                                rel="noreferrer"
                                                referrerPolicy="no-referrer"
                                            >
                                                <FontAwesomeIcon icon={faTwitter} />
                                            </a>
                                        )}
                                        {config?.sidebar?.facebook && (
                                            <a
                                                href={config?.sidebar?.facebook}
                                                target="_blank"
                                                rel="noreferrer"
                                                referrerPolicy="no-referrer"
                                            >
                                                <FontAwesomeIcon icon={faFacebook} />
                                            </a>
                                        )}
                                    </SocialList>
                                </li>
                            </ol>
                        </Nav>
                    </NavWrapper>
                </>
            )}
        </>
    );
};

export default MobileNav;
