import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import React, { useContext, useEffect } from 'react';
import UIfx from 'uifx';
import switchOffMp3 from '../../static/switch-off.mp3';
import switchOnMp3 from '../../static/switch-on.mp3';
import { GlobalContext } from '../context/GlobalContextProvider';

let switchOnSound: UIfx;
let switchOffSound: UIfx;
if (typeof window !== 'undefined') {
    switchOnSound = new UIfx(switchOnMp3);
    switchOffSound = new UIfx(switchOffMp3);
}

const DarkModeToggle = () => {
    const context = useContext(GlobalContext);

    useEffect(() => {
        context.setTheme(window.__theme);
    });

    return (
        <div>
            <ThemeToggler>
                {({ theme, toggleTheme }: { theme: string; toggleTheme: (mode: string) => void }) => {
                    if (theme === 'dark') {
                        return (
                            <FontAwesomeIcon
                                icon={faSun}
                                onClick={() => {
                                    switchOnSound?.play();
                                    context.setTheme('light');
                                    toggleTheme('light');
                                }}
                            />
                        );
                    } else {
                        return (
                            <FontAwesomeIcon
                                icon={faMoon}
                                onClick={() => {
                                    switchOffSound?.play();
                                    context.setTheme('dark');
                                    toggleTheme('dark');
                                }}
                            />
                        );
                    }
                }}
            </ThemeToggler>
        </div>
    );
};

export default DarkModeToggle;
