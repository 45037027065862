import React from 'react';

export const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer>
            Thanks for visiting!
            <br />© {year} Rishav Anand. All Rights Reserved.
        </footer>
    );
};

export default Footer;
