import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { GlobalContext } from '../context/GlobalContextProvider';
import { BlogMobileNav, MobileNav } from './MobileNav';
import Nav, { BlogNav } from './Nav';

const LogoText = styled.div`
    margin-left: 1rem;
`;

const LogoWrapper = styled.div`
    padding: 1.5rem;
    display: flex;
    align-items: center;
`;

const MenuIconWrapper = styled.div`
    padding: 1.5rem;
    font-size: 1.5rem;
    color: #000026;
    z-index: 1000;
`;

const MainHeader = () => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    const context = useContext(GlobalContext);

    return (
        <>
            <Link to="/">
                <LogoWrapper>
                    {context.theme === 'dark' ? (
                        <img src={'/logo-dark.svg'} height="32" />
                    ) : (
                        <img src={'/logo.svg'} height="32" />
                    )}
                </LogoWrapper>
            </Link>
            {mobile ? (
                <MenuIconWrapper>
                    <MobileNav />
                </MenuIconWrapper>
            ) : (
                <Nav />
            )}
        </>
    );
};

const BlogHeader = () => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    const context = useContext(GlobalContext);
    return (
        <>
            <Link to="/">
                <LogoWrapper>
                    {context.theme === 'dark' ? (
                        <img src={'/logo-dark.svg'} height="32" />
                    ) : (
                        <img src={'/logo.svg'} height="32" />
                    )}
                </LogoWrapper>
            </Link>
            {mobile ? (
                <MenuIconWrapper>
                    <BlogMobileNav />
                </MenuIconWrapper>
            ) : (
                <BlogNav />
            )}
        </>
    );
};

const Header = ({ type = 'main' }: { type?: 'main' | 'blog' }) => {
    if (type === 'main') return <MainHeader />;
    else if (type === 'blog') return <BlogHeader />;
    else return <></>;
};

export default Header;
