import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import { Link as LinkWithScroll } from 'react-scroll';
import styled from 'styled-components';
import config from '../config';
import Button from './Button';
import DarkModeToggle from './DarkModeToggle';

export interface NavProps {
    items?: {
        navigateTo: string;
        smooth: boolean;
        offset: number;
        duration: number;
        text: string;
    }[];
}

const NavWrapper = styled.div`x    
    display: flex;
    justify-content: center;
    font-size: 1rem;

    ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: 'row';
    }

    li {
        margin-left: 10px;
        margin-right: 10px;

        a:visited{
            color: var(--textColor);
        }
    }

    li:last-child {
        margin-right: 1.5rem;
    }

    a:hover {
        background-color: #0652dd;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
        font-weight: bold;
    }

    a {
        text-decoration: none;
    }
`;

export const Nav = () => {
    return (
        <NavWrapper>
            <ol>
                {config.nav?.items?.map(item => {
                    const isPageNav = item.navigateTo.includes('/');
                    if (isPageNav)
                        return (
                            <li key={`menu-item-${item.navigateTo}`}>
                                <Link to={item.navigateTo}>{item.text}</Link>
                            </li>
                        );
                    else
                        return (
                            <li key={`menu-item-${item.navigateTo}`}>
                                <LinkWithScroll
                                    to={item.navigateTo}
                                    smooth={item.smooth}
                                    offset={item.offset}
                                    duration={item.duration}
                                >
                                    {item.text}
                                </LinkWithScroll>
                            </li>
                        );
                })}
                <li>
                    <DarkModeToggle />
                </li>
                <li>
                    <LinkWithScroll to="contact" smooth={true} offset={-70} duration={500}>
                        <Button padding="large">
                            Start Your Project &nbsp;&nbsp;
                            <FontAwesomeIcon icon={faArrowRight} />{' '}
                        </Button>
                    </LinkWithScroll>
                </li>
            </ol>
        </NavWrapper>
    );
};

export const BlogNav = () => {
    return (
        <NavWrapper>
            <ol>
                <li>
                    <Link to="/">About</Link>
                </li>
                <li>
                    <Link to="/blog">Blog</Link>
                </li>
                <li>
                    <DarkModeToggle />
                </li>
                <li>
                    <Link to="/">
                        <Button padding="large">
                            View Portfolio &nbsp;&nbsp;
                            <FontAwesomeIcon icon={faArrowRight} />{' '}
                        </Button>
                    </Link>
                </li>
            </ol>
        </NavWrapper>
    );
};

export default Nav;
