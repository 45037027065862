import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import styled from 'styled-components';
import config from '../config';
import { Footer } from './Footer';
import Header from './Header';
import Loader from './Loader';
import Sidebar from './Sidebar';

const LayoutWrapper = styled.div`
    background-color: var(--bg);
    color: var(--textNormal);
    transition: background-color 0.5s ease;
    display: flex;
    flex-direction: column;
`;

const SidebarWrapper = styled.div`
    height: 100vh;
    position: fixed;
    width: 50px;
    z-index: 3;
    color: white;
    background-color: var(--sidebarBg);
`;

const ContentWrapper = styled.div<{ sidebar?: boolean }>`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex-grow: 1;
    margin-left: ${props => (props.sidebar ? '50px' : '0px')};
    position: relative;
`;

const HeaderWrapper = styled.div<{ mobile?: boolean; fixed: boolean }>`
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;

    a {
        text-decoration: none;

        &:hover {
            background-color: #0652dd;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            cursor: pointer;
            font-weight: bold;
        }

        &:visited {
            color: var(--textColor);
        }
    }
`;

const MainContent = styled.div<{ isFullScreenHeight: boolean }>`
    margin: 0 0;
    width: 100%;
    display: flex;
    min-height: ${props => (props.isFullScreenHeight ? '100vh' : undefined)};
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
`;

const FooterWrapper = styled.div`
    text-align: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 0.9rem;
`;

const Layout = ({ children, isFullScreenHeight = true }: { children: any; isFullScreenHeight?: boolean }) => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    return (
        <>
            <Loader />
            <LayoutWrapper>
                {!breakpoints.xs && !breakpoints.sm && (
                    <SidebarWrapper>
                        <Sidebar {...config.sidebar} />
                    </SidebarWrapper>
                )}
                <ContentWrapper sidebar={!breakpoints.xs && !breakpoints.sm}>
                    <HeaderWrapper mobile={mobile} fixed={true}>
                        <Header />
                    </HeaderWrapper>
                    <MainContent isFullScreenHeight={isFullScreenHeight}>{children}</MainContent>
                    <FooterWrapper>
                        <Footer />
                    </FooterWrapper>
                </ContentWrapper>
            </LayoutWrapper>
        </>
    );
};

export const BlogPageLayout = ({
    children,
    isFullScreenHeight = false,
}: {
    children: any;
    isFullScreenHeight?: boolean;
}) => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    return (
        <LayoutWrapper>
            <ContentWrapper>
                <HeaderWrapper mobile={mobile} fixed={false}>
                    <Header type="blog" />
                </HeaderWrapper>
                <MainContent isFullScreenHeight={isFullScreenHeight}>{children}</MainContent>
                <FooterWrapper>
                    <Footer />
                </FooterWrapper>
            </ContentWrapper>
        </LayoutWrapper>
    );
};

export default Layout;
