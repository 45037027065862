import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GlobalContext } from '../context/GlobalContextProvider';

const StyledLoader = styled.div`
    position: fixed;
    top: 0,
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: red;
    z-index: 200;
    justify-content: center;
    align-items: center;
    color: var(--textNormal);
    background-color: var(--bg);
    flex-direction: column;
`;

export const Loader = () => {
    const [isMounted, setIsMounted] = useState(false);
    const context = useContext(GlobalContext);

    useEffect(() => {
        const timeout = setTimeout(() => setIsMounted(true), 1000);
        return () => clearTimeout(timeout);
    }, []);

    if (!isMounted)
        return (
            <StyledLoader>
                {context.theme === 'dark' ? (
                    <img src="logo-dark.svg" height="64" style={{ marginBottom: '1rem' }} />
                ) : (
                    <img src="logo.svg" height="64" style={{ marginBottom: '1rem' }} />
                )}
            </StyledLoader>
        );
    else return <></>;
};

export default Loader;
